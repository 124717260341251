import { Component, OnInit } from "@angular/core";
import { CarouselConfig } from "ngx-bootstrap/carousel";

@Component({
  selector: "app-projects",
  templateUrl: "./projects.component.html",
  providers: [
    {
      provide: CarouselConfig,
      useValue: { interval: 5000, noPause: false, showIndicators: false },
    },
  ],
  styleUrls: ["./projects.component.scss"],
})

export class ProjectsComponent implements OnInit {
  slides = [
    { image: "assets/images/1.png", text: "Checkers", link: "http://checkers.jaysingh.info" },
    { image: "assets/images/2.png", text: "Old Portfolio", link: "http://oldport.jaysingh.info" },
    { image: "assets/images/3.png", text: "WannaBeCookk", link: "https://wanna-be-cookk.herokuapp.com/"},
  ];

  noWrapSlides = false;
  showIndicator = true;

  constructor() {}

  ngOnInit(): void {}
}
