<div class="contact-page" id="contact">
<section class="section1">
  <h1>Contact Information</h1>
  <p>265 Cushman Ave.<br>Revere, M.A 02151</p>
  <p>singhjay269@gmail.com</p>
  <p>617-955-2906</p>
  <a href="https://github.com/singhjatinder/" target="_blank"><img class="icons" src="assets/images/git.png" alt="GIT Profile"></a>
  <a href="https://www.linkedin.com/in/jatinder-singh-1b9844a3/" target="_blank"><img class="icons" src="assets/images/in.png" alt="LinkedIn Profile"></a>
</section>
  <section class="section2">
    <h1>Send an Email</h1>
    <form method="post" action="assets/php/sendemail.php">
      <label for="fname">First Name:</label>
      <input id="fname" type="text" name="fname" autocomplete="off" placeholder="Enter First Name"><br><br>
      <label for="lname">Last Name:</label>
      <input id="lname" type="text" name="lname" autocomplete="off" placeholder="Enter Last Name"><br><br>
      <label for="email">Email</label>
      <input id="email" type="text" name="email" autocomplete="off" placeholder="Enter your email"><br><br>
      <label for="subject">Subject</label><br>
      <input id="subject" type="text" name="subject" autocomplete="off" placeholder="Enter Subject"><br><br>
      <label for="message">Message</label><br>
      <textarea rows="5" id="message" name="message" autocomplete="off" placeholder="Enter you message here..."></textarea>
      <input class="button" type="submit" value="SEND">
    </form>
  </section>
</div>
