<div class="resume-container" id="resume">
  <section class="sec1" appScrollAnimation>
    <h1 class="animate">Resume</h1>
  </section>
  <section class="sec2" appScrollAnimation>
    <iframe class="animatepdf"
      src="assets/pdf/Jatinder%20Singh%20-%20WD%20-%20Resume.pdf"
      frameBorder="0"
      scrolling="auto"
      height="750px"
      width="100%"
    ></iframe>
    <a href="assets/pdf/Jatinder%20Singh%20-%20WD%20-%20Resume.pdf"><button>Click here to Download</button></a>
  </section>
</div>
