<div class="projects-container">
   <h1 class="title">Projects</h1>
   <div class="carousel">
    <carousel [noWrap]="noWrapSlides" [showIndicators]="showIndicator">
       <slide *ngFor="let slide of slides; let index=index">
          <a [href]="slide.link"><img [src]="slide.image" [alt]="slide.text"  style="display: block; width: 100%; height: 100%"></a>
          <div class="carousel-caption c-text"> 
             <p >{{slide.text}}</p>
          </div>
       </slide>
    </carousel>
 </div>
</div>
